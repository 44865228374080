import { Directive, Input } from '@angular/core';
import {
  TooltipDirective as FluidTooltipDirective,
  TooltipOptions,
} from '@engie-group/fluid-design-system-angular';

@Directive({
  selector: '[hyppoTooltip]',
  standalone: true,
})
export class TooltipDirective extends FluidTooltipDirective {
  static readonly defaultConfig: TooltipOptions = {
    arrowPlacement: 'center',
    hasArrow: true,
    isAnimated: true,
    isInverse: false,
    isStandalone: true,
    placement: 'bottom',
    tooltipId: 'tooltip',
    label: 'This is your tooltip',
  };

  private label: TooltipOptions['label'] = TooltipDirective.defaultConfig.label;
  @Input('hyppoTooltip') set tooltip(label: TooltipOptions['label']) {
    this.label = label;
    this.tooltipOptions = {
      ...TooltipDirective.defaultConfig,
      ...this.tooltipOptions,
      placement: this.tooltipPlacement,
      label,
    };
  }

  private placement: TooltipOptions['placement'] =
    TooltipDirective.defaultConfig.placement;
  @Input() set tooltipPlacement(placement: TooltipOptions['placement']) {
    this.placement = placement;
    this.tooltipOptions = {
      ...TooltipDirective.defaultConfig,
      ...this.tooltipOptions,
      label: this.label,
      placement,
    };
  }
  get tooltipPlacement(): TooltipOptions['placement'] {
    return this.placement;
  }

  override show(): void {
    if (this.label) {
      super.show();
    }
  }

  override hide(): void {
    if (this.label) {
      super.hide();
    }
  }
}
